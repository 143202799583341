import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { Fade, AttentionSeeker, Zoom } from "react-awesome-reveal";
import { MdWavingHand } from "react-icons/md";
import DeveloperSVG from "~/assets/svg/developer";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            hi
            iam
            webdev
            product
            desc1
            desc2
            desc3
            desc4
            desc5
          }
    }
`;
const Img = styled.img`
  width: 20rem;
  height: 20rem;
`;

const MainText = styled.p`
    font-size: 3em;
    color: #d6d4d4;
    font-family: "Roboto";
    font-weight: 700;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    line-height: 4.25rem;
    padding: 0 0 0 4rem;
    @media (max-width: 1250px) {
      padding: 0;
    }
`;
const MainText2 = styled(MainText)`
    font-size: 1em;
    line-height: 2rem;
    border: 2px solid #6c63ff;
    border-radius: 10px;
    text-align: center;
    letter-spacing: 2px;
    padding: 1rem 0;
    margin-bottom: 2rem;
`;
const MainText3 = styled(MainText)`
    font-size: 1em;
    line-height: 2rem;
    letter-spacing: 2px;
    padding: 2rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    min-width: 100%;
    min-height: 90vh;
    @media (max-width: 1250px) {
        position: relative;
        z-index: 2;
    }
`;
const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    flex-wrap: nowrap;
    @media(max-width: 1250px) {
      padding: 0 2rem;
    }
`;
const RightContainer = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    @media (max-width: 1250px) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
`;
const Button = styled.button`
    color: #6c63ff;
    padding: 10px 50px;
    border-radius: 13px;
    display: block;
    font-family: "Roboto";
    text-decoration: none;
    margin: 2rem 0 0 4rem;
    padding: 1rem;
    width: auto;
    font-size: 3em;
    border: 2px solid #6c63ff;
    text-align: center;
    @media (max-width: 1250px) {
      margin: 2rem 0 0 0;
    }
`;

const IndexPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();

  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Saba Hossein - Home"
        description="Senior Project Manager with over 10 years of experience and with a large set of supporting skills. Experienced in Typescript, Javascript, React, Gatsby, Adobe XD, UX/UI Design, Node.js, HTML, CSS, Styled Components, React Spring, AWS, Imgix, HeadLess CMS, and many more."
        keywords={["saba"]}
        url={props.location.pathname}
      />
      <Container>
          <LeftContainer>
            <MainText>{strings.get("hi")}</MainText>
            <MainText>{strings.get("iam")}</MainText>
            <MainText style={{fontSize:"2em"}}>{strings.get("webdev")}</MainText>
            
            <Button as="a" href="/contact">Contact</Button>
        </LeftContainer>
        <RightContainer><Zoom><DeveloperSVG /></Zoom></RightContainer>
      </Container>
    </>
  )
}

export default IndexPage
